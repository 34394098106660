import { ComponentStyleConfig } from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
   baseStyle: {
      bg: "beige.200",
      _disabled: {
         cursor: "not-allowed",
      },
      _hover: {
         opacity: "0.6",
      },
      _focus: {
         boxShadow: "none",
      },
   },
   variants: {
      base: {
         border: "2px solid",
         borderColor: "beige.200",
         bg: "beige.200",
         _hover: {
            opacity: "0.6",
            cursor: "pointer",
            bg: "beige.200",
         },
      },
      outline: {
         border: "2px solid",
         borderColor: "gray.400",
         bg: "inherit",
         color: "inherit",
         _hover: {
            bg: "transparent",
            opacity: "0.6",
         },
      },
   },

   defaultProps: {
      variant: "base",
   },
};

export { Button };
