import { extendTheme } from "@chakra-ui/react";
import { Button } from "./ButtonStyle";
import { Divider } from "./DividerStyle";
import { Menu } from "./MenuStyle";
import { Select } from "./SelectStyle";
import { Tabs } from "./TabStyle";

export const theme = extendTheme({
   styles: {
      global: {
         body: {
            bg: "blue.600",
            color: "gray.200",
            height: "100%",
         },
      },
   },

   fonts: {
      heading: "Barlow",
      body: "Barlow",
   },

   colors: {
      blue: {
         "200": "#61DCFB",
         "600": "#333f4A",
         "700": "#34404b",
         "800": "#2e3841",
         "900": "#2C353E",
      },

      gray: {
         "400": "#A8A8B3",
      },
      beige: {
         "200": "#7A6746",
         "300": "#A18C6D",
      },
   },

   components: {
      Button,
      Menu,
      Divider,
      Select,
      Tabs,
   },
});
