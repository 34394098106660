import { ComponentStyleConfig } from "@chakra-ui/react";

const Select: ComponentStyleConfig = {
   parts: ["field"],
   baseStyle: {
      field: {
         option: {
            bg: "blue.800",
         },
      },
   },

   variants: {},
};

export { Select };
