import { AppProps } from "next/app";
import { useEffect } from "react";
import Router, { useRouter } from "next/router";
import { ChakraProvider } from "@chakra-ui/react";
import NProgress from "nprogress";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { Provider } from "react-redux";
import { theme } from "../../styles/theme";

import ErrorBoundary from "../components/errors/ErrorBoundary";

const onRedirectCallback = appState => {
   // Use Next.js's Router.replace method to replace the url
   Router.replace(appState?.returnTo || "/");
};

function MyApp({ Component, pageProps }: AppProps) {
   const router = useRouter();

   useEffect(() => {
      const handleStart = () => {
         NProgress.start();
      };
      const handleStop = () => {
         NProgress.done();
      };

      router.events.on("routeChangeStart", handleStart);
      router.events.on("routeChangeComplete", handleStop);
      router.events.on("routeChangeError", handleStop);

      return () => {
         router.events.off("routeChangeStart", handleStart);
         router.events.off("routeChangeComplete", handleStop);
         router.events.off("routeChangeError", handleStop);
      };
   }, [router]);

   const queryClient = new QueryClient();

   return (
      <QueryClientProvider client={queryClient}>
         <ChakraProvider theme={theme}>
            <Auth0Provider
               domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
               clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
               redirectUri={
                  typeof window !== "undefined" && window.location.origin
               }
               onRedirectCallback={onRedirectCallback}
               audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
            >
               <ErrorBoundary>
                  <Component {...pageProps} />
               </ErrorBoundary>
            </Auth0Provider>
         </ChakraProvider>
         <ReactQueryDevtools />
      </QueryClientProvider>
   );
}
export default MyApp;
