/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */

import React, { Component, ErrorInfo, ReactNode } from "react";
import { Button, Flex, Heading, Text, Box, HStack } from "@chakra-ui/react";
import Image from "next/image";
import * as Sentry from "@sentry/nextjs";
import Head from "next/head";

interface Props {
   children: ReactNode;
}

interface State {
   hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
   public state: State = {
      hasError: false,
   };

   public static getDerivedStateFromError(_: Error): State {
      // Update state so the next render will show the fallback UI

      return { hasError: true };
   }

   public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      // You can use your own error logging service here

      Sentry.withScope(scope => {
         Object.keys(errorInfo).forEach(key => {
            scope.setExtra(key, errorInfo[key]);
         });
         Sentry.captureEvent(error);
      });

      console.log({ error, errorInfo });
   }

   public render() {
      // Check if the error is thrown

      if (this.state.hasError) {
         // You can render any custom fallback UI

         return (
            <>
               <Head>
                  <title>Error</title>
               </Head>
               <Flex
                  w="100vw"
                  h="100vh"
                  alignItems="center"
                  justifyContent="center"
                  flexDir="column"
               >
                  <Image
                     src="/av-logo.png"
                     alt="Alta Vista Investimentos"
                     width={300}
                     height={208}
                  />
                  <Heading as="h5">Erro!!</Heading>
                  <Box maxWidth="460px" my="6">
                     <Text textAlign="center">
                        Infelizmente tivemos um erro ao processar os dados,
                        tente novamente e caso o erro persista, entre em contato
                        conosco
                     </Text>
                  </Box>
                  <HStack>
                     <Button as="a" href="/" variant="outline" borderRadius="0">
                        Página inicial
                     </Button>
                     <Button
                        borderRadius="0"
                        onClick={() => this.setState({ hasError: false })}
                     >
                        Tentar novamente
                     </Button>
                  </HStack>
               </Flex>
            </>
         );
      }

      // Return children components in case of no error

      return this.props.children;
   }
}

export default ErrorBoundary;
