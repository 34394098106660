const Divider = {
   variants: {
      solid: {
         borderColor: "beige.300",
      },
   },

   defaultProps: {
      variant: "solid",
   },
};

export { Divider };
