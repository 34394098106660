import { ComponentStyleConfig } from "@chakra-ui/react";

const Menu: ComponentStyleConfig = {
   parts: ["menu", "list", "item"],
   baseStyle: {
      menu: {
         boxShadow: "lg",
         rounded: "lg",
         flexDirection: "column",
         py: "2",
      },

      item: {
         fontWeight: "medium",
         lineHeight: "normal",
         bg: "blue.800",
         color: "blue.900",
         _hover: {
            bg: "beige.200",
         },
         _focus: {
            bg: "beige.200",
         },
         mb: 1,
      },

      list: {
         bg: "blue.800",
         borderColor: "blue.900",
      },
   },

   sizes: {
      sm: {
         item: {
            fontSize: "0.75rem",
            px: 2,
            py: 1,
         },
      },

      md: {
         item: {
            fontSize: "0.875rem",
            px: 3,
            py: 2,
         },
      },
   },

   variants: {
      bold: {
         item: {
            fontWeight: "bold",
            bg: "blue.800",
         },

         menu: {
            boxShadow: "xl",
         },
      },

      base: {
         list: {
            bg: "blue.900",
            borderColor: "blue.900",
         },

         item: {
            color: "gray.50",
            bg: "blue.900",
            _hover: {
               bg: "beige.200",
            },
            _focus: {
               bg: "beige.200",
            },
            mb: 1,
         },

         menu: {
            bg: "blue.800",
         },
      },
   },

   defaultProps: {
      size: "md",
      variant: "base",
   },
};

export { Menu };
